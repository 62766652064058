<template>
  <div>
    <!-- Table Families Card -->
 
    <b-card
      no-body
      class="mb-0"
    >
      <b-modal
          :ref="`my-modal`"
          centered
          ok-only
          ok-title="حسنا"
          cancel-variant="outline-secondary"
          title-tag="div"
        >
      

  <b-card title="بيانات " v-if="formData">
   <b-row v-if="formData.status">
    <b-col cols="2">الحالة</b-col>
      <b-col cols="10">{{formData.status.name}}</b-col>
   </b-row>
     <b-row v-if="formData.deadline">
      <b-col cols="4">تاريخ الانتهاء </b-col>
      <b-col cols="8">{{ formData.deadline }}</b-col>
    </b-row>

    <b-row v-if="formData.prepared_by">
      <b-col cols="4">من قبل </b-col>
      <b-col cols="8">{{ formData.prepared_by }} </b-col>
    </b-row>
    

    <b-row v-if="formData.notes">
      <b-col cols="4">ملاحظات</b-col>
      <b-col cols="8">{{ formData.notes }}</b-col>
    </b-row>
 <b-row v-if="formData.history && formData.history.length">
      <b-col cols="12">
        <b-card title="تفاصيل التاريخ">
          <b-list-group>
            <b-list-group-item v-for="detail in formData.history" :key="detail.id">
              <p> الحالة الجديدة: {{ detail.new_status.name }}</p>
             
              <p>ملاحظات: {{ detail.notes }}</p>
                 <p>تم التعديل من قبل: {{ detail.changed_by_user }}</p>
                    <p>تاريخ: {{ detail.date }}</p>
              <!-- Include other details from the 'details' array as needed -->
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>

 
  </b-card>
          <!-- </b-card-code> -->
        </b-modal>
      <div class="app-action">
        <b-row>
          <b-col
            cols="6"
            md="6"
            class="
                d-flex

                mb-1 mb-md-0
              "
          >
            <label>عرض</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <!-- <label>SortBy</label>
                <v-select
                style="width:200px"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sortOptions"
                  v-model="sort"
                  multiple
                  :clearable="true"
                   />
             -->
          </b-col>
        </b-row>
      </div>

      <!-- Update Mail Folder Dropdown -->

      <b-table
        ref="refFamiliesListTable"
        :items="fetchPurches"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :current-page="currentPage"
        :per-page="perPage"
      >
        <!-- Column: editfield -->
        <template #cell(notes)="data">
          <b-form-textarea

            id="helperInput"

            v-model="data.item.notes"
            placeholder="ملاحظة"
          />
        </template>
        <!-- Column: Actions -->
        
      
        <template #cell(action_id)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
          <b-dropdown-item
             :to="{ name: 'purches-order-approve-id', params: { id: data.item.id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">عرض</span>
            </b-dropdown-item>
            <b-dropdown-item @click="sendResponse(data.item.id, 1,data.item.notes)">
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">موافق</span>
            </b-dropdown-item>
  <b-dropdown-item @click="showModal(data.item)">
              
              <span class="align-middle ml-50">تفاصيل</span>
            </b-dropdown-item>
            <b-dropdown-item @click="sendResponse(data.item.id, 0,data.item.notes)">
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">رفض</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BListGroup,
  BListGroupItem,
  BModal,
  BFormTextarea
} from 'bootstrap-vue'
import Vue from 'vue'
import vSelect from 'vue-select'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

import formValidation from '@core/comp-functions/forms/form-validation'
import PurchesStoreModule from './purschaseStoreModule.js'
import useRequestItemList from './List'
import store from '@/store'

export default {
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(() => {})
    const Purches_APP_STORE_MODULE_NAME = 'app-Purches'

    // Register module
    if (!store.hasModule(Purches_APP_STORE_MODULE_NAME)) { store.registerModule(Purches_APP_STORE_MODULE_NAME, PurchesStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(Purches_APP_STORE_MODULE_NAME)) { store.unregisterModule(Purches_APP_STORE_MODULE_NAME) }
    })
    // Mail Selection
    // ------------------------------------------------
    const Form = ref({
      name: '',
      phone: '',
      email: '',
      location: '',
      notes: '',
    })
   
    const {
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,

      fetchPurches,

      selectedItem,
      deletePurches,
      sendResponse,
      // Extra Filters
    } = useRequestItemList()

    return {
      sendResponse,
      deletePurches,
    
      refFormObserver,
      resetForm,
      Form,
      getValidationState,
      // Sidebar

      search: null,
      fetchPurches,

      filterOn: [],
      searchTerm: '',
      tableColumns,
      selectedItem,

      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,
    }
  },

  components: {
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
 BListGroup,
  BListGroupItem,
   BModal,
    vSelect,
  },
   methods: {
    showModal(data) {
     // console.log(data);
      this.formData=data
      this.$refs["my-modal"].show();
    },},
     data() {
    return {
      formData: {},
     
    
    };
  },
}
</script>

<style scoped>
div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.app-action {
  margin: 18px;
}
.d-block.invalid-feedback {
    color: red;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
